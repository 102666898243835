<template>
  <div class="">
    <div class="text-end">
      <button type="button" class="btn btn-secondary mb-3" @click.prevent="goToCreate">新增通路</button>
    </div>
    <div class="my-5">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        @on-cell-click="onCellClick"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 10,
          perPageDropdownEnabled: true,
          perPageDropdown: [10, 20],
          nextLabel: '下一頁',
          prevLabel: '上一頁',
          rowsPerPageLabel: 'Rows per page',
          allLabel: '全部',
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'btn'">
            <!-- <button type="button" class="btn btn-danger btn-sm me-1 mb-1 mb-md-0" style="white-space:nowrap;">刪除</button> -->
            <button type="button" class="btn btn-main btn-sm me-1 mb-1 mb-md-0" style="white-space:nowrap;">修改</button>
            <!-- <p>{{ props.row.id }}</p> -->
          </span>
          <span v-else-if="props.column.field == 'ena'">
            <input type="checkbox" name="ena" id="ena" v-model="props.row.ena">
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </vue-good-table>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
/* global $ */
import { mapActions, mapGetters } from 'vuex';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

const Swal = require('sweetalert2');

export default {
  name: 'Admin__Categories',
  components: {
    VueGoodTable,
  },
  data() {
    return {
      page: 1,
      data: [],
      noData: false,
      tempCourse: {},
      is_enabled: true,
      createModalRole: '系統管理員',
      columns: [
        {
          label: '通路名稱',
          field: 'name',
        },
        {
          label: '代碼',
          field: 'code',
        },
        {
          label: '分潤比例',
          field: 'diversionTaxRate',
        },
        {
          label: '使用費比例',
          field: 'royaltyTaxRate',
        },
        {
          label: '起始日期',
          field: 'startTime',
        },
        {
          label: '結束日期',
          field: 'endTime',
        },
        {
          label: '動作',
          field: 'btn',
        },
      ],
      rows: [
        { id: 0, name: 'test1', code: '123EaB', diversionTaxRate: 0.28, royaltyTaxRate: 0.4, startTime: '2022-09-08', endTime: '2022-09-22' },
        { id: 1, name: 'test2', code: '123EaB', diversionTaxRate: 0.28, royaltyTaxRate: 0.4, startTime: '2022-09-08', endTime: '2022-09-22' },
        { id: 2, name: 'test3', code: '123EaB', diversionTaxRate: 0.28, royaltyTaxRate: 0.4, startTime: '2022-09-08', endTime: '2022-09-22' },
      ],
      metaTitle: '分潤設定 - 元學堂',
    };
  },
  metaInfo: {
    title: "分潤設定 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '分潤設定 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    getData() {
      const vm = this;
      this.updateLoading(true);
      this.$http.get(`${this.$API_PATH}/Merchant/SalesChannel`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.updateLoading(false);
        this.rows = [...res.data.data]
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '無權限取得列表',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '',
            '錯誤，請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push('/');
            }
          });
        }
      });
    },
    onCellClick(param) {
      const vm = this;
      if (param.column.field == 'btn') {
        if (param.event.target.innerText == '刪除') {
          // axios delete
        } else if (param.event.target.innerText == '修改') {
          this.$router.push(`/admin/profit/${param.row.id}`);
        }
      }
    },
    goToCreate(id) {
      this.$router.push(`/admin/profit`);
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  created() {
    this.cid = this.$route.params.id;
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
  .pageNum {
    cursor: pointer;
    text-decoration: none;
    &.disabled {
      pointer-events: none;
      color: gray;
      cursor: default;
    }
  }
  ::v-deep #vgt-table tbody tr td:nth-of-type(1)
  {
    text-align: left;
  }

  ::v-deep #vgt-table tbody tr td:nth-of-type(2) 
  {
    text-align: center;
  }

  ::v-deep #vgt-table thead tr th:last-child {
    width: 280px !important;
  }
  
</style>
